import React, { useEffect, useState } from 'react' //lazy

import ClientCaptcha from 'react-client-captcha'
import 'react-client-captcha/dist/index.css'
import './Login.css'
const Captcha = ({ onClick }) => {
  const setCode = (e) => {
    // console.log(e);
    onClick(e)
  }

  useEffect(() => {
    window.removeEventListener('keyup', () => {})
  }, [])
  return (
    <div className='captcha-box'>
      <ClientCaptcha
        fontFamily='Aria'
        backgroundColor='#000'
        fontColor='#fff'
        chars={'0123456789'}
        width={100}
        height={54}
        charsCount={4}
        refreshButton={false}
        captchaCode={setCode}
      />
    </div>
  )
}

export default Captcha
