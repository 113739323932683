import types from '../actions/types'

const initialState = {
  open: false,
  name: ''
}

export default (state = initialState, { type, payload }: any) => {
  switch (type) {
    case types.OPEN_MODAL:
      return {
        ...state,
        ...payload,
        open: true
      }
    case types.CLOSE_MODAL:
      return {
        ...state,
        open: false,
        name: ''
      }
    default:
      return state
  }
}
