import styled from 'styled-components'
import btnGold from 'assets/images/button/btn-gold.png'
import btnGoldActive from 'assets/images/button/btn-gold-active.png'

import { Button as AntButton } from 'antd'
export const Button = styled(AntButton)`
  background-image: url(${btnGold});
  color: #000;
  width: 150px;
  height: 45px;
  background-color: unset;
  background-size: 100% 100%;
  border-color: transparent;
  background-position: center;
  cursor: pointer;
  div {
    display: none;
  }
  &:hover {
    border-color: transparent !important;
    background-image: url(${btnGoldActive});
  }
  &:focus {
    border-color: transparent;
  }
  &:disabled {
    cursor: not-allowed;
    filter: grayscale(1);
  }

  span {
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    display: block;
    margin-top: 4px;
  }
  @media screen and (max-width: 768px) {
    width: 90px;
    height: 25px;
    span {
      font-size: 12px;
      margin-top: 2px;
    }
  }
`
