import { Empty } from 'antd'
/** Config */
import { UrlInternal } from 'common/constants/endpoints'
import useWindowSize from 'hooks/useWindowSize'
import moment from 'moment'
import React, { useCallback, useRef, useState } from 'react' //lazy
import { useHistory } from 'react-router-dom'
/** Ant Lib*/
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { getTimeRemaning } from 'utils/getTime'
/** Utils */
import moneyConvert from 'utils/moneyConvert'
/** Components*/
import CircleProgress from '../CircularProgress'

/** Style Local */
import * as S from './style'

interface Props {
  data?: any
}

//props: Props
const MainSlider = ({ data = [] }: Props) => {
  const history = useHistory()
  const slider: any = useRef<any>(null)
  const { width }: any = useWindowSize()
  const [showItem, setShowItem] = useState<any>({ show: false, item: '' })

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000
  }

  /** Next to slide */
  const nextToSlide = useCallback(() => {
    slider.current.slickNext()
  }, [])

  /** Back to slide */
  const backToSlide = useCallback(() => {
    slider.current.slickPrev()
  }, [])

  /** Show info on mobile size*/
  const showInfo = (show: any, item: number) => {
    setShowItem({ show, item })
  }

  return (
    <S.Container className='main-slider'>
      <Slider ref={slider} {...settings}>
        {data?.map((v: any, i: any) => (
          <S.SlideItem key={i}>
            <div
              className={`item item-left ${
                showItem?.show && showItem?.item === i ? 'showInfo' : ''
              }`}
              onClick={() => (width <= 768 ? showInfo(true, i) : () => {})}
            >
              <div className='item-left__title'>{v?.title}</div>
              <div className='item-left__title author'>{v?.authorName}</div>
              <div className='item-left__art'>
                {v?.thumbnail ? (
                  <img src={v?.thumbnail} alt='art' />
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description='No Image'
                  />
                )}
              </div>
              <div className='item-left__artist'>{v?.authorName}</div>
              <div className='item-left__size'>
                {`${v?.artSize ? v?.artSize?.trim() + ',' : ''} ${
                  v?.artMaterial
                    ? v?.artMaterial?.trim() + (v?.yearOfItem ? ',' : '')
                    : ''
                } ${v?.yearOfItem?.trim()}`}
              </div>
              {data.length > 1 && (
                <div
                  className='ti ti-arrow-circle-left back'
                  onClick={backToSlide}
                ></div>
              )}
              <div
                className='open-information'
                onClick={() => showInfo(!showItem?.show, i)}
              >
                <span>Details</span>
                {/* <span className="ti ti-angle-double-right"></span> */}
              </div>
            </div>
            <div className='item-line'></div>
            <div
              className='item item-right'
              onClick={() =>
                width <= 768 ? showInfo(!showItem?.show, i) : () => {}
              }
            >
              <div className='item-right__title'>진행중인 공동구매</div>
              <div className='item-right__tab'>
                <div className='item-right__tab-item active'>
                  {v?.subCategory}
                </div>
                {/* <div className="item-right__tab-item">
                  {v?.title}, {v?.authorName}
                </div> */}
                <div className='item-right__tab-item'>
                  남은기간 :{' '}
                  {getTimeRemaning(moment().toISOString(), v?.endDate)}일
                </div>
              </div>
              <div className='item-right__chart'>
                <CircleProgress
                  percentage={v?.targetPercent}
                  content={
                    <S.Content>
                      <span className='percentage'>{v?.targetPercent}%</span>
                      <span className='chart-line'></span>
                      <span className='chart-content'>공동구매</span>
                      <span className='chart-content'>진행률</span>
                    </S.Content>
                  }
                  color='#5d7160'
                />
              </div>
              <div className='item-right__price'>
                <div className='item-right__price-left'>
                  <span className='bold'>공동구매 목표금액</span>
                  <span>{moneyConvert(v?.target, true)}원</span>
                </div>
                <div className='item-right__price-line'></div>
                <div className='item-right__price-right'>
                  <span className='bold'>공동구매 달성액</span>
                  <span>{moneyConvert(v?.currentMoney, true)}원</span>
                </div>
              </div>
              <button className='item-right__btn'>
                공동구매 참여하기{` `}
                <i className='ti ti-angle-right'></i>
              </button>
              {data.length > 1 && (
                <div
                  className='ti ti-arrow-circle-right next'
                  onClick={nextToSlide}
                ></div>
              )}
            </div>
          </S.SlideItem>
        ))}
      </Slider>
    </S.Container>
  )
}

export default MainSlider
