import React, { lazy, useEffect } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import 'antd/dist/reset.css'

/** Common */
import { UrlInternal } from 'common/constants/endpoints'
import GlobalStyles from 'common/style/GlobalStyle'
import Normalize from 'common/style/Normalize'
import defaultTheme from 'common/style/themes/default'
import { meApi } from 'apis'
import types from 'store/actions/types'
import Modal from 'components/Modal'

/** Router Global */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PublicRoute, PrivateRoute } from 'router' //PrivateRoute,
import { PopupCheck, WaittingPage } from 'components'

/** Utils */
import ScrollToTop from 'utils/ScrollToTop'
import { useDispatch } from 'react-redux'

/** Page */
const Login = lazy(() => import('pages/Login'))

// const Home = lazy(() => import("pages/Home"));
const Register = lazy(() => import('pages/Register'))
const Home = lazy(() => import('pages/Home'))
// const Register = lazy(() => import("pages/Register"));
const Deposit = lazy(() => import('pages/Deposit'))
const Payment = lazy(() => import('pages/Payment'))
const Points = lazy(() => import('pages/Points'))
// const HistoryBettingPower = lazy(() => import("pages/HistoryBetting/Power"));
// const HistoryBettingSolo = lazy(() => import("pages/HistoryBetting/Solo"));
// const HistoryBettingLive = lazy(() => import("pages/HistoryBetting/Live"));
// const HistoryBettingSpecial = lazy(
//   () => import("pages/HistoryBetting/Special")
// );
// const HistorySportSolo = lazy(() => import("pages/HistorySport/Solo"));
// const HistorySportLive = lazy(() => import("pages/HistorySport/Live"));
// const HistorySportSpecial = lazy(() => import("pages/HistorySport/Special"));
const CustomerCenterPage = lazy(() => import('pages/CustomerCenterPage'))
const CustomerCenterPageRegister = lazy(
  () => import('pages/CustomerCenterPageRegister')
)
const CustomerCenterPageDetail = lazy(
  () => import('pages/CustomerCenterPageDetail')
)
const Message = lazy(() => import('pages/Message'))
const NotiAndEvent = lazy(() => import('pages/NotiAndEventPage'))
const NotificationDetail = lazy(() => import('pages/NotificationDetail'))
// const Event = lazy(() => import("pages/EventPage"));
const EventDetail = lazy(() => import('pages/EventDetail'))
const CasinoPage = lazy(() => import('pages/CasinoPage'))
// const CasinoPageDetail = lazy(() => import("pages/CasinoPageDetail"));
const PartnerPage = lazy(() => import('pages/PartnerPage'))
const PartnerRate = lazy(() => import('pages/PartnerConflic/PartnerRate'))
const PartnerAddChild = lazy(
  () => import('pages/PartnerConflic/PartnerAddChild')
)
const PartnerListDeposit = lazy(() => import('pages/PartnerList/DepositList'))
const PartnerListWithdraw = lazy(() => import('pages/PartnerList/WithdrawList'))
const PartnerListBetting = lazy(() => import('pages/PartnerList/BettingList'))
const PartnerListRolling = lazy(() => import('pages/PartnerList/RollingList'))
const PartnerPartnerListDeposit = lazy(
  () => import('pages/PartnerList/PartnerDepositList')
)
const PartnerPartnerListWithdraw = lazy(
  () => import('pages/PartnerList/PartnerWithdrawList')
)
const PartnerPartnerListDepositAll = lazy(
  () => import('pages/PartnerList/PartnerDepositListAll')
)
const PartnerPartnerListWithdrawAll = lazy(
  () => import('pages/PartnerList/PartnerWithdrawListAll')
)
const PartnerChildList = lazy(() => import('pages/PartnerRanking/ChildList'))
const PartnerRankingDeposit = lazy(
  () => import('pages/PartnerRanking/DepositRanking')
)
const PartnerRankingWithdraw = lazy(
  () => import('pages/PartnerRanking/WithdrawRanking')
)
const PartnerDepositMoney = lazy(
  () => import('pages/PartnerConflic/PartnerMoneyDeposit')
)
const PartnerWithdrawMoney = lazy(
  () => import('pages/PartnerConflic/PartnerMoneyWithdraw')
)
const PartnerRankingBetting = lazy(
  () => import('pages/PartnerRanking/BettingRanking')
)
const PartnerRankingBettingDetail = lazy(
  () => import('pages/PartnerRanking/BettingRankingDetail')
)
const PartnerRankingRolling = lazy(
  () => import('pages/PartnerRanking/RollingRanking')
)
// const SlotGamePage = lazy(() => import("pages/SlotGamePage"));
// const SlotGamePageDetail = lazy(() => import("pages/SlotGamePageDetail"));
// const MiniGamePage = lazy(() => import("pages/MiniGamePage"));
// const MiniGameDetailPage = lazy(() => import("pages/MiniGameDetailPage"));
// const SportsBettingPage = lazy(() => import("pages/SportsBettingPage"));
const InfoPage = lazy(() => import('pages/InfoPage'))
// const PageNotFound = lazy(() => import("pages/PageNotFound"));

const Blank = lazy(() => import('pages/Blank'))

// console.log("getDataById", a);

export const getMe = async (dispatch: any) => {
  dispatch({ type: 'WAITTING' })
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: any = await meApi()
    // console.log(res);
    if (res.result === false) {
      alert(res.message)
      dispatch({ type: 'STOP_WAITTING' })
    } else {
      dispatch({ type: 'STOP_WAITTING' })
      dispatch({
        type: types.GET_DATA_USER,
        dataUser: res.data
      })
    }
  } catch (error) {
    console.log(error)
    dispatch({ type: 'STOP_WAITTING' })
  }
}
const App: React.FC = () => {
  const dispatch = useDispatch()
  const userAuthen = localStorage.getItem('User')

  useEffect(() => {
    if (userAuthen) {
      getMe(dispatch)
    }
  }, [userAuthen])

  return (
    <ThemeProvider theme={defaultTheme}>
      <Normalize />
      <GlobalStyles />
      <Router>
        <ScrollToTop />
        <Modal />
        <Switch>
          <PublicRoute exact path={UrlInternal.HOME} component={Home} />
          <PublicRoute exact path={UrlInternal.LOGIN} component={Login} />
          <PublicRoute exact path={UrlInternal.REGISTER} component={Register} />
          {/* <PublicRoute exact path={UrlInternal.USER} component={InfoPage} />
          <PublicRoute exact path={UrlInternal.PAYMENT} component={Payment} /> */}
          <PublicRoute exact path={UrlInternal.DEPOSIT} component={Deposit} />
          <PublicRoute exact path={UrlInternal.CASINO} component={CasinoPage} />

          <PublicRoute exact path={UrlInternal.POINT} component={Points} />
          <PublicRoute
            exact
            path={UrlInternal.CUSTOMER_CENTER}
            component={CustomerCenterPage}
          />
          <PublicRoute
            exact
            path={UrlInternal.PARTNER}
            component={PartnerPage}
          />
          <PublicRoute
            exact
            path={UrlInternal.PARTNER_CHILD_LIST}
            component={PartnerChildList}
          />
          <PublicRoute
            exact
            path={UrlInternal.PARTNER_DEPOSIT_RANKING}
            component={PartnerRankingDeposit}
          />
          <PublicRoute
            exact
            path={UrlInternal.PARTNER_WITHDRAW_RANKING}
            component={PartnerRankingWithdraw}
          />
          <PublicRoute
            exact
            path={UrlInternal.PARTNER_BETTING_RANKING}
            component={PartnerRankingBetting}
          />
          <PublicRoute
            exact
            path={UrlInternal.PARTNER_ADD_CHILD_DETAIL}
            component={PartnerAddChild}
          />
          <PublicRoute
            exact
            path={UrlInternal.PARTNER_RATE_DETAIL}
            component={PartnerRate}
          />
          <PublicRoute
            exact
            path={UrlInternal.PARTNER_DEPOSIT_LIST}
            component={PartnerListDeposit}
          />
          <PublicRoute
            exact
            path={UrlInternal.PARTNER_WITHDRAW_LIST}
            component={PartnerListWithdraw}
          />
          <PublicRoute
            exact
            path={UrlInternal.PARTNER_BETTING_LIST}
            component={PartnerListBetting}
          />
          <PublicRoute
            exact
            path={UrlInternal.PARTNER_ROLLING_LIST}
            component={PartnerListRolling}
          />
          <PublicRoute
            exact
            path={UrlInternal.PARTNER_PARTNER_DEPOSIT_LIST}
            component={PartnerPartnerListDeposit}
          />
          <PublicRoute
            exact
            path={UrlInternal.PARTNER_PARTNER_WITHDRAW_LIST}
            component={PartnerPartnerListWithdraw}
          />
          <PublicRoute
            exact
            path={UrlInternal.PARTNER_PARTNER_DEPOSIT_LIST_ALL}
            component={PartnerPartnerListDepositAll}
          />
          <PublicRoute
            exact
            path={UrlInternal.PARTNER_PARTNER_WITHDRAW_LIST_ALL}
            component={PartnerPartnerListWithdrawAll}
          />
          <PublicRoute
            exact
            path={UrlInternal.PARTNER_DEPOSIT_MONEY}
            component={PartnerDepositMoney}
          />
          <PublicRoute
            exact
            path={UrlInternal.PARTNER_WITHDRAW_MONEY}
            component={PartnerWithdrawMoney}
          />
          <PublicRoute
            exact
            path={UrlInternal.PARTNER_ROLLING_RANKING}
            component={PartnerRankingRolling}
          />
          <PublicRoute
            exact
            path={UrlInternal.PARTNER_BETTING_RANKING_DETAIL}
            component={PartnerRankingBettingDetail}
          />

          <PublicRoute
            exact
            path={UrlInternal.PARTNER_CHILD_LIST}
            component={PartnerChildList}
          />
          <PublicRoute exact path={UrlInternal.PAYMENT} component={Payment} />
          <PublicRoute exact path={UrlInternal.DEPOSIT} component={Deposit} />
          <PublicRoute exact path={UrlInternal.USER} component={InfoPage} />
          {/* <PublicRoute exact path={UrlInternal.POINT} component={Points} /> */}
          <PublicRoute
            exact
            path={UrlInternal.CUSTOMER_CENTER}
            component={CustomerCenterPage}
          />
          <PublicRoute
            exact
            path={UrlInternal.CUSTOMER_CENTER_REGISTER}
            component={CustomerCenterPageRegister}
          />
          <PublicRoute
            exact
            path={UrlInternal.CUSTOMER_CENTER_DETAIL}
            component={CustomerCenterPageDetail}
          />
          <PublicRoute exact path={UrlInternal.MESSAGE} component={Message} />
          <PublicRoute
            exact
            path={UrlInternal.NOTIFICATION}
            component={NotiAndEvent}
          />
          <PublicRoute
            exact
            path={UrlInternal.EVENT}
            component={NotiAndEvent}
          />
          <PublicRoute
            exact
            path={UrlInternal.NOTIFICATION_DETAIL}
            component={NotificationDetail}
          />
          <PublicRoute
            exact
            path={UrlInternal.EVENT_DETAIL}
            component={EventDetail}
          />
          <PublicRoute exact path={UrlInternal.BLANK} component={Blank} />
        </Switch>
        {/* <FooterPage /> */}
      </Router>
      {/* <PopupCheck /> */}
      <WaittingPage />
    </ThemeProvider>
  )
}

export default App
