import React from 'react'
import { AuthModal } from '../style'
import btnClose from 'assets/images/button/btn-close.png'
import { useDispatch } from 'react-redux'
import types from 'store/actions/types'

type Props = {
  children: React.ReactNode
}

const AuthLayout: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch({ type: types.CLOSE_MODAL, payload: { open: false } })
  }

  return (
    <AuthModal>
      <div onClick={handleClose} className='close-icon'>
        <img src={btnClose} alt='' />
      </div>
      {children}
    </AuthModal>
  )
}

export default AuthLayout
