import React from 'react'
import { useSelector } from 'react-redux'
import { CustomModal } from './style'
import LoginModal from './layout/Login'
import RegisterModal from './layout/Register'
import Qna from './layout/Qna'
const MyModal: React.FC = () => {
  const { open, name } = useSelector((state: any) => state.modal)

  return (
    open && (
      <CustomModal
        width={'fit-content'}
        open={open}
        closeIcon={''}
        closable={false}
        footer={null}
      >
        {name === 'login' && <LoginModal />}
        {name === 'register' && <RegisterModal />}
        {name === '1:1' && <Qna />}
      </CustomModal>
    )
  )
}

export default MyModal
